import { FC } from 'react';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { useTranslation } from 'react-i18next';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import Activities from '../../components/activity-list/Activities';
import { ActivitiesContext } from '../../contexts/ActivitiesContext';
import { SelectItemContextProvider } from '../../contexts/select-items/SelectItemsContext';
import { Roles } from '../../models/Role';
import usePermissions from '../../hooks/permissions/usePermissions';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';

const AssetModule: FC = () => {
  const { t } = useTranslation(['assets', 'common']);
  const hasPermission = usePermissions();
  const { featureFlags } = useFeatureFlags();

  return (
    <div className="bg-background-1 flex min-h-full flex-col ">
      <div className="h-0 flex-grow overflow-y-auto">
        <TopNavPortal>
          <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
            {t('assets:list.heading')}
          </Heading>
        </TopNavPortal>
        <div className="px-6 pb-6">
          <div className="-mx-6 mt-0 flex-grow">
            <ActivitiesContext.Provider value={{ hasContextMenu: true, type: 'activities', forCurrentUser: !hasPermission(Roles.TeamMember) }}>
              <SelectItemContextProvider disable={!hasPermission(Roles.TeamMember) || !featureFlags.dataExport}>
                <Activities skeletonRows={15} />
              </SelectItemContextProvider>
            </ActivitiesContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetModule;
